import styled from "styled-components"
import { devices } from "theme/devices"

const NewsWrapper = styled.div`
  display: flex;
  /* align-items: flex-start; */
  justify-content: space-between;
  flex-wrap: wrap;

  ${devices.tabletL} {
    margin-top: 4rem;
  }
`

export default { NewsWrapper }
