import React from "react"
import parse from "html-react-parser"

import { Wrapper, WrapperSizes, Header, News } from "components"
import S from "./NewsContent.styled"

const NewsContent = ({ title, news, _ref }) => (
  <Wrapper size={WrapperSizes.SMALL} _ref={_ref}>
    <Header title={title} />
    <S.NewsWrapper>
      {news.map(post => (
        <News
          key={post?.node?.id}
          date={`${post?.node?.categories?.nodes[0]?.name} | ${new Date(
            post?.node?.date
          ).toLocaleDateString()}`}
          title={post?.node?.title}
          lead={parse(post?.node?.content, { trim: true })}
          imageFluid={
            post?.node?.post?.postCover?.localFile
          }
        />
      ))}
    </S.NewsWrapper>
  </Wrapper>
)

export default NewsContent
