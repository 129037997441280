import React, { useEffect, useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { gsap } from "gsap"

import SubpageLayout from "layouts/SubpageLayout"
import { SEO, Footer } from "components"
import { Loader } from "ui"
import NewsContent from "./NewsContent"

const query = graphql`
  {
    allWpPage(filter: { news: { newsLang: { regex: "/ES|EN/" } } }) {
      edges {
        node {
          title
          news {
            newsLang
            title
          }
        }
      }
    }
    allWpPost(filter: { post: { language: { regex: "/ES|EN/" } } }) {
      edges {
        node {
          title
          id
          date
          content
          categories {
            nodes {
              name
              id
            }
          }
          post {
            language
            postCover {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
      }
    }
  }
`

const NewsTemplate = ({ location, lang }) => {
  const { allWpPage: {edges: languageDataVersions }, allWpPost: { edges: languagePostVersions } } = useStaticQuery(query) // eslint-disable-line
  const [pageContent, setPageContent] = useState(null)
  const [loading, setLoading] = useState(true)

  const newsSectionRef = useRef(null)

  useEffect(() => {
    if (loading) return

    const tl = gsap.timeline()

    tl.fromTo(
      newsSectionRef.current,
      { y: "+=50", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
      }
    )
  }, [loading, newsSectionRef])

  useEffect(() => {
    const page = languageDataVersions.find(
      ({ node: { news } }) => news.newsLang === lang
    )

    const posts = languagePostVersions.filter(
      ({ node }) => node?.post?.language === lang
    ).sort((a,b)=> {
      return  new Date(b.node.date) - new Date(a.node.date)
    })

    setPageContent({ news: page?.node?.news, title: page?.node?.title, posts })

    const id = setTimeout(() => setLoading(false), 500)
    return () => clearTimeout(id)
  }, [])

  return (
    <>
      <SEO title={pageContent?.title || ""} />
      <SubpageLayout path={location.pathname}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <NewsContent
              title={pageContent?.news?.title || ""}
              news={pageContent?.posts || []}
              _ref={newsSectionRef}
            />
            <Footer />
          </>
        )}
      </SubpageLayout>
    </>
  )
}

NewsTemplate.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default NewsTemplate
