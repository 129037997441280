import React from "react"
import PropTypes from "prop-types"
import NewsTemplate from "templates/NewsTemplate"

const NewsPage = ({ location }) => (
  <NewsTemplate location={location} lang="ES" />
)

NewsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default NewsPage
